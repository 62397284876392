<template>
  <div class="com-user-ProductTrends">
    <div
      class="trend-content"
      :class="
        source.dataList.length === 1
          ? 'd-flex flex-column justify-content-center'
          : ''
      "
    >
      <div
        class="d-flex align-items-center justify-content-center item"
        v-for="(item, index) in source.dataList"
        :key="index"
      >
        <div
          class="d-flex flex-column align-items-center left"
          :class="{
            'left-line': source.dataList.length > 1,
            'left-active': formModel.currentIndex === index,
          }"
        >
          <div
            class="d-flex flex-column align-items-center activeV"
            :class="{
              active:
                formModel.currentIndex === index && source.dataList.length != 1,
              oneActive: source.dataList.length === 1,
            }"
          >
            {{ item.date }}
          </div>
        </div>

        <div
          class="content"
          :class="{
            'content-active':
              formModel.currentIndex === index && source.dataList.length != 1,
            'one-content': source.dataList.length === 1,
          }"
        >
          <img class="content-bg" src="../../../../public/img/bg-active.png" />
          <div class="title">{{ item.title }}</div>
          <div class="info" v-html="item.shortContent"></div>
          <div class="d-flex align-items-center justify-content-end">
            <span
              v-if="item.isOverflow"
              @click="loadMoreClick(item, index)"
              class="load-more"
              >查看更多<a-icon type="right"
            /></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreMessage from "@/components/user/index/MoreMessage";

export default {
  data() {
    return {
      formModel: {
        currentIndex: -1,
      },
      source: {
        dataList: [],
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.api.service
        .fpc_oapi_productDynamic_selectAllPublished({}, {})
        .then(async (res) => {
          await this.api.toast(res, 0);
          this.source.dataList = res.data.map(
            ({ title, content, publishDate }) => {
              // 去掉前后空格，回车分隔
              content = content.trim();
              let arr = content.split("\n");
              // 如果大于两行，则显示更多
              const isOverflow = arr.length > 2;
              content = content.replaceAll("\n", "<br/>");
              let shortContent = content;
              if (isOverflow) {
                shortContent = `${arr[0]}<br/>${arr[1]}`;
              }
              return {
                title,
                content: content.replaceAll("\n", "<br/>"),
                date: publishDate,
                isOverflow,
                shortContent,
              };
            }
          );
        });
    },
    loadMoreClick({ content, title, date }, index) {
      this.formModel.currentIndex = index;
      this.api.component(MoreMessage, {
        title: "产品动态",
        data: {
          content,
          title,
          date,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.com-user-ProductTrends {
  .trend-content {
    height: 100%;
    overflow: auto;
    padding: 20px 30px;
    .item {
      .left {
        font-family: DINCondensed-Bold;
        font-size: 24px;
        color: #333;
        font-weight: bold;
        .activeV::before {
          content: "";
          display: block;
          width: 3px;
          background: #e3e3e3;
          height: 50px;
        }

        .activeV::after {
          content: "";
          display: block;
          width: 3px;
          background: #e3e3e3;
          height: 50px;
        }
        .active::before {
          content: "";
          display: block;
          width: 3px;
          background: @primary-color;
          height: 50px;
        }

        .active::after {
          content: "";
          display: block;
          width: 3px;
          background: @primary-color;
          height: 50px;
        }

        .oneActive::before {
          content: "";
          display: block;
          width: 3px;
          height: 312px;
          background-image: linear-gradient(
            180deg,
            rgba(249, 251, 254) 0%,
            #e2e2e3 100%
          );
        }

        .oneActive::after {
          content: "";
          display: block;
          width: 3px;
          height: 312px;
          background-image: linear-gradient(
            180deg,
            #e3e3e3 0%,
            rgba(255, 255, 255) 100%
          );
        }
      }

      .left-active {
        color: #00a497;
      }

      .left-line::before {
        content: "";
        display: block;
        width: 3px;
        background: #e3e3e3;
        height: 40px;
      }

      .left-line::after {
        content: "";
        display: block;
        width: 3px;
        background: #e3e3e3;
        height: 40px;
      }

      .content {
        flex: 1;
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #ededed;
        border-radius: 4px;
        min-height: 173px;
        max-height: 600px;
        padding: 20px;
        margin-left: 20px;
        position: relative;
        z-index: 1;
        .title {
          font-family: PingFangSC-Medium;
          font-size: 18px;
          color: #000000;
          letter-spacing: 0;
          font-weight: bold;
          height: 50px;
          line-height: 50px;
          overflow: hidden;
        }

        .info {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #333333;
          line-height: 20px;
          word-break: break-all;
        }

        .load-more {
          cursor: pointer;
          font-size: 12px;
          text-decoration: underline;
          margin-top: 20px;
        }

        .load-more:hover {
          opacity: 0.7;
        }

        .content-bg {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: -1;
          width: 180px;
          height: auto;
        }
      }

      .content:hover {
        opacity: 0.7;
      }

      .content:before {
        width: 20px;
        height: 20px;
        background: #fff;
        content: "";
        position: absolute;
        top: 50%;
        left: -10px;
        margin-top: -5px;
        transform: rotate(45deg);
        border-bottom: 1px solid #ededed;
        border-left: 1px solid #ededed;
      }

      .content-active {
        border: 1px solid @primary-color;
        box-shadow: 0px 16px 12px 0px rgba(0, 164, 151, 0.07);
        background-image: linear-gradient(
          185deg,
          #f6fcfb 0%,
          rgba(255, 255, 255, 0) 31%
        );
      }

      .content-active:before {
        width: 20px;
        height: 20px;
        background: #fff;
        content: "";
        position: absolute;
        top: 50%;
        left: -10px;
        margin-top: -5px;
        transform: rotate(45deg);
        border-bottom: 1px solid @primary-color;
        border-left: 1px solid @primary-color;
      }

      .one-content {
        padding: 80px 20px;
      }
    }
  }
}
</style>