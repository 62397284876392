var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"com-user-ProductTrends"},[_c('div',{staticClass:"trend-content",class:_vm.source.dataList.length === 1
        ? 'd-flex flex-column justify-content-center'
        : ''},_vm._l((_vm.source.dataList),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-items-center justify-content-center item"},[_c('div',{staticClass:"d-flex flex-column align-items-center left",class:{
          'left-line': _vm.source.dataList.length > 1,
          'left-active': _vm.formModel.currentIndex === index,
        }},[_c('div',{staticClass:"d-flex flex-column align-items-center activeV",class:{
            active:
              _vm.formModel.currentIndex === index && _vm.source.dataList.length != 1,
            oneActive: _vm.source.dataList.length === 1,
          }},[_vm._v(" "+_vm._s(item.date)+" ")])]),_c('div',{staticClass:"content",class:{
          'content-active':
            _vm.formModel.currentIndex === index && _vm.source.dataList.length != 1,
          'one-content': _vm.source.dataList.length === 1,
        }},[_c('img',{staticClass:"content-bg",attrs:{"src":require("../../../../public/img/bg-active.png")}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"info",domProps:{"innerHTML":_vm._s(item.shortContent)}}),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(item.isOverflow)?_c('span',{staticClass:"load-more",on:{"click":function($event){return _vm.loadMoreClick(item, index)}}},[_vm._v("查看更多"),_c('a-icon',{attrs:{"type":"right"}})],1):_vm._e()])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }